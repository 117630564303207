.badgeBox {
  box-sizing: border-box;
  span {
    display: inline-flex;
    max-width: 100%;
    align-items: center;
    min-height: 2.8rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    border-radius: 3px;
    color: var(--p-text, #f5f6fa);
  }
}
